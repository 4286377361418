/* Pinned components */

/* DESKTOP */

.document__pinned-component--right-bottom {
  display: inline-flex;
  position: fixed;
  right: 0px;
  bottom: 0px;
  justify-content: flex-end;
  width: auto;
  height: auto;
  margin-right: 40px;
  margin-bottom: 40px;
  z-index: 200;
}

/* TABLET */

@media screen and (min-width: 481px) and (max-width: 768px) {
  .document__pinned-component--right-bottom {
    margin-right: 32px;
    z-index: 200;
  }
}

/* MOBILE */

@media screen and (max-width: 480px) {
  .document__pinned-component--right-bottom {
    margin-right: 16px;
    z-index: 200;
  }
}
