@media screen and ( max-width: 978px ){

  .Form-Col.rp8 {
    width: 60%;
  }

  .Form-Col.lp8 {
    width: 40%;
  }

  .Form-Col.rp8c {
    width: 60%;
  }

  .Form-Col.lp8c {
    width: 40%;
  }

}
