@media screen and ( max-width: 705px ){

  
  .Image.Big-Cards {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100% !important;
  }

  .Image.Lot-Of-Staff {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100% !important;
  }

  .Image.API {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100% !important;
  }

  .Image.Info {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100% !important;
  }

  .Image.Box_With_Cards{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100% !important;
  }

  .Image.Personal-Area{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100% !important;
  }

  .Image.Custom-Page{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100% !important;
  }

  .Image.All-Settings{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100% !important;
  }

  .Image.Devs{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100% !important;
  }

  .Image.About{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100% !important;
  }

  .Image.Email{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100% !important;
  }

  .Image.OpenInfo{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100% !important;
  }
  
  .App-Row.Devs.pb90 {
    padding-bottom: 0px !important;
  }

  .App-Row.pb160{
    padding-bottom: 80px !important;
  }

  .App-Col{
    width  : 100% !important;
    display: inline-block !important;
  }

  .App-Col.rp8,.App-Col.lp8{
    padding: 0 !important;
  }

  .App-Col.rp8 {
    padding-bottom: 32px !important;
  }

  .App-Col[name=header]{
    padding-bottom: 32px !important;
  }

  .Label.H1{
    width  : 100% !important;
    display: inline-block;
  }

  .Label-Text.H2{
    line-height: 29px !important;
  }

  .Label-Text.H1{
    width    : auto !important;
    font-size: 24px !important;
  }

  .Label-Text.H2{
    font-size: 24px !important;
  }

  .Label-Text.H3{
    font-size: 20px !important;
  }

  .Label-Text.H4{
    font-size: 14px !important;
    line-height: 20px !important;
  }

  .Label-Text.H5{
    font-size: 14px !important;
  }

  .Label-Text.P{
    font-size  : 14px !important;
    line-height: 19px !important;
  }

  .Button.Header-Navigation-Button {
    width: calc(100% - 48px);
  }

  .App-Row[name=pb24]{
    padding-bottom: 24px !important;
  }

  .App-Row[name=pb8]{
    padding-bottom: 8px !important;
  }

  .App-Col[name=footer]{
    display: table-footer-group !important;
  }

  .App-Col[name=hidden]{
    display: none !important;
  }

  .App-Col.App-Col.w33[name=triple]:nth-child(n){
    width        : 100% !important;
    padding-right: 0px !important
  }

  .Label.Center{
    text-align: left !important;
  }

  .App-Row.pb64{
    padding-bottom: 32px !important;
  }

  .col-4 {
    width          : 100% !important;
    display        : flex;
    flex-direction : row;
    justify-content: space-between;
    flex-flow      : wrap;
    padding-bottom : 64px;
  }

  .col-4:nth-child(3) {
    width          : 100% !important;
    display        : flex;
    flex-direction : row;
    justify-content: space-between;
    flex-flow      : wrap;
    padding-bottom : 64px;
  }

  .Tariffs-Blocks{
    width : 100%;
    height: 309px;
  }

  .Tariffs-Col2 {
    width: 100%;
  }

  .order1 {
    order: 1;
  }
  .order2 {
    order: 2;
  }

  .order3 {
    order       : 3;
    padding-left: 0px !important;
    padding-top : 45px !important;
  }

  .mgn40 {
    margin-bottom: 40px;
  }
  
  .mg8 {
    margin-bottom: 0px !important;
  }
  
  .mg64 {
    margin-bottom: 20px;
  }
  
  .mg90 {
    margin-bottom: 20px;
  }

  .Label-Text.H1.t3h1{
    font-size: 24px !important;
  }
  .Tariffs-Col2 .mg40 {
    margin-bottom: 20px;
  }

  .Cont-4 {
    width         : 100%;
    padding-bottom: 24px;
  }

  .C-title {
 
    width        : 100%;
    height       : 32px;
    margin-bottom: 8px;
  }
  
  .C-text {
  
    width        : 100%;
    height       : 44px;
    margin-bottom: 8px;
  }
  
  .C-btn {
    background-color: none;
  }

  .BigNum {
    font-size  : 120px;
    font-family: "Montserrat-Bold";
    line-height: 92px;
    width      : 100px;
  }
  
  .App-Cont {
    width          : 100%;
    display        : flex;
    justify-content: right;
    padding-bottom : 80px;
  }

  .NText {
    width: 82% !important;
  }

  .mr12 {
    margin-right: 0px;
  }
  
  .small-part {
    height      : 72px;
    width       : 30%;
    margin-right: 0px !important;
    text-align  : left !important;
  }
  .small-part1 {
    width       : 50%;
    margin-right: 0px !important;
    text-align  : left !important;
  }
  .f-lang {
    text-align: left;
  
  }

  .t-btn3 {
    top: -22px !important;
  }

  .t-btn2 {
    top: 11px !important;
  }
}

