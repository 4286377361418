@media screen and ( max-width: 846px ){

  .Form-Col.rp8 {
    width: 100%;
    order: 2;
  }

  .Form-Col.lp8 {
    width: 525px;
    order: 1;
  }

  .Form-Col.rp8c {
    width: 100%;
  }

  .Form-Col.lp8c {
    width: 525px;
  }

  .form-control {
    width: 525px;
  }

  .form-control.textarea {
    width: 525px;
  }
  .select-css {
    width: 525px;
  }

  .FormSend {
    width: 508px !important;
  }

  .short {
    width: 525px ;
  }
  
  .dropdown {   
    width      : 525px;
    position   : relative;
    user-select: none;
    z-index    : 1;
  }
  .Form-pad {
    padding-bottom: 40px;
    padding-top   : 40px;
  }

  .File-Button-Text {
    width: 320px !important;
  }

  .Image.OpenInfo {
    width: 400px;
    height: 350px;
  }

  .Image-Source.OpenInfo {
    width: 426px;
    height: 234px;
  }


   
}