@media screen and ( max-width: 1242px ){

  .App-Col.Manager-Question {
    padding-left: 60px !important;
  }

  .t-btn2 {
    top: -20px;
  }

}
