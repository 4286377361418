.App-Header{
  top              : 0px;
  width            : 100%;
  padding          : 24px 0;
  z-index          : 3;
  transform        : translateZ(3px);
  -webkit-transform: translateZ(3px);
  position         : fixed;
  box-sizing       : border-box;
  background       : var(--static-white) !important;
  box-shadow       : 0 1px 0 0 rgba(19,28,40,.07);
}

.App-Header1{
  top              : 0px;
  width            : 100%;
  padding          : 24px 0;
  z-index          : 3;
  transform        : translateZ(3px);
  -webkit-transform: translateZ(3px);
  position         : fixed;
  box-sizing       : border-box;
  background       : var(--static-white) !important;

}

.App-Header-Content{
  left       : calc( 50% - 640px );
  width      : 1280px;
  display    : inline-flex;
  position   : relative;
  background : var(--static-white);
  align-items: center;
}

.App-Header-Navigation{
  width          : 100%;
  display        : inline-flex;
  position       : relative;
  align-items    : center;
  justify-content: flex-end;
}