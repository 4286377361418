:root {
  --ascent: #00AF69;
  --ascent-hover: #008741;
  --ascent-pressed: #004B2D;
  --ascent-disabled: #96D7B9;
  --additional-dark: #003719;
  --additional-light: #C8FAE6;
  --additional-light-2: #EAF0EE;
  --additional: #EAF0EE;
  --static-white: #FFFFFF;
  --disabled: #EBEBEB;
  --overlay: rgb(255, 255, 255, 0.25);

  --text-primary: #141414;
  --text-secondary: #525252;
  --text-placeholder: #989898;
  --text-disabled: #C0C0C0;

  --text-footer: #FFFFFF;
  --text-footer-icon: #D6DCDA;

  --elements-primary: #141414;
  --elements-secondary: #525252;
  --elements-placeholder: #989898;
  --elements-disabled: #C0C0C0;

  --divider: #C0C0C0;
  --ascent-disabled: #96D7B9;

  --background-status-success: #CFF5E6;
  --background-status-warning: #FCE9C6;
  --background-status-error: #FFCECE;

  --text-error: #F74949;
  --elements-error: #F74949;

  --dropdown-color-border: #C0C0C0;
  --dropdown-color-text: #FFFFFF;

  --main-font-family: 'Inter', sans-serif;

  /* Font boldness */

  --font-weight-light: 300;
  --font-weight-normal: 400;
  --font-weight-medium: 500;
  --font-weight-semibold: 600;
}

@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter-Light.ttf') format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter-SemiBold.ttf') format('truetype');
  font-weight: 600;
}
