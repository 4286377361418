@media screen and ( max-width: 900px ){

  .Form-Col.rp8 {
    width: 64%;
  }

  .Form-Col.lp8 {
    width: 36%;
  }

  .Form-Col.rp8c {
    width: 64%;
    }
  
    .Form-Col.lp8c {
    width: 36%;
    }

    .C-title {
    
    width        : 344px;
    height       : 58px;
    margin-bottom: 24px;
  }
  
  .C-text {

    width        : 344px;
    height       : 66px;
    margin-bottom: 32px;
  }
  
}
  