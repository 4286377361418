@media screen and ( max-width: 1300px ){

  .App-Col.rp20 {
    width: 100% !important;
    display: block !important;
  }

  .File-Button-Text {
    width: 95% !important;
  }

  .File-Button-Title-Wrapper {
    width: 75% !important;
  }

}
