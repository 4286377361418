@media screen and ( max-width: 1024px ){

  .App-Header{
    padding: 34px 48px !important;
  }

  .App-Header1{
    padding: 34px 48px !important;
  }

  .App-Body-Content{
    width: calc( 100% - 96px ) !important;
  }

  .App-Header-Navigation{
    display: none !important;
  }

  .App-Header-Content{
    justify-content: flex-end !important;
  }

  .Burger-Content-Header {
    padding: 32px 46px 20px 32px !important;
    justify-content: flex-end !important;
  }

  .Burger{
    left   : 48px !important;
    display: block !important;
  }

  .Burger-Icon.Close{
    left           : 48px !important;
    width          : 44px !important;
    height         : 44px !important;
    background-size: 44px !important;
  }

  .App-Footer{
    padding: 40px 48px !important;
  }
  
  .Logo-Footer {
    position       : relative;
    width          : 1024px;
    height         : 60px;
    display        : flex;
    align-items    : flex-start;
    justify-content: space-between;
    flex-direction : row;
    flex-wrap      : wrap;
    left           : calc( 50% - 640px );
    order          : 1;
  }


  .Button-Text.Footer-Navigation-Link{
    font-size: 14px !important;
  }

  .Button.Footer-Navigation-Link{
    margin-right: 0px !important;
  }

  .Label-Text.H1{
    font-size: 32px !important;
  }

  .Label-Text.H2{
    font-size  : 24px !important;
    line-height: 33px !important;
  }

  .Label-Text.H3{
    font-size: 24px !important;
  }

  .Label-Text.H4{
    font-size  : 14.5px !important;
    font-size  : 16px !important;
    line-height: 20px !important;
  }

  .Label-Text.H5{
    font-size: 13px !important;
  }

  .Image-Source.Info{
    /* background     : url( ../../Components/Image/Images/Info-Big.png ) !important; */
    background-size: 100% !important;
  }

  .Image.About{
    width : 320px !important;
    height: 300px !important;
  }
  
  .App-Row.p64-80{
    padding: 60px 40px !important;
  }

  .Form-Col.rp8 {
    width: 56%;
  }

  .Form-Col.lp8 {
    width: 44%;
  }

  .Form-Col.rp8c {
    width: 56%;
  }

  .Form-Col.lp8c {
    width: 44%;
  }

  .Cont-1 {
    width          : 44%;
    display        : flex;
    justify-content: space-between;
  }
  
  .Cont-2 {
    width          : 50%;
    display        : flex;
    justify-content: space-between;
  }
  
  .Cont-3 {
    width          : 50%;
    display        : flex;
    justify-content: space-between;
    margin         : 40px auto 0 auto;
  }

  .N1 {
    width      : 90px !important;
    font-size  : 150px;
    font-family: "Montserrat-Bold";
    line-height: 116px;
  }

  .NPtext {
    color      : #212429  !important;
    font-family: 'OpenSans-Regular' !important;
    font-size  : 16px !important;
    line-height: 22px !important;
    font-style : normal !important;
    font-weight: normal !important;
  }

  .Label-Text.P {
    font-size: 16px !important;
    line-height: 20px !important;
    font-weight: 400 !important;
  }

  .mr22 {
    margin-right: 0px;
    width: 100px !important;
  }

  .mg64 {
    margin-top   : 0px !important;
    margin-bottom: 64px;
  }
}