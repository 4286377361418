@import url(../../../src/styles/styles.css);

@media screen and ( max-width: 640px ){

  .File-Button {
    height: 126px !important;
    display: block !important;
    padding: 16px 8px !important;
  }

  .App-Col {
    width: 100% !important;
  }
  
  .App-Col.rp20 {
    padding-right: 0px !important;
  }

  .File-Button-Title-Wrapper {
    width: 100% !important;
  }
  
  .File-Button-Icon {
    margin: 0px !important;
  }
  
  .File-Button-Text {
    padding-left: 4px !important;
    font-size: 14px !important;
    line-height: 16px !important;
  }

  .File-Button {
    padding: 24px 8px !important;
    height: 80px !important;
  }

  .File-Button-Divider {
    height: 1px !important;
    border-bottom: 1px solid var(--divider); 
    border-left: none !important;
  }

  .File-Button-View-Wrapper {
    padding-left: 0px !important;
    width: 100% !important;
    display: block !important;
  }

  .File-Button-Icon-Arrow {    
    margin-top: 4px !important;
  }

  .File-Button-Press-Wrapper {
    justify-content: flex-end !important;
    padding-top: 8px !important;
  }

}
  