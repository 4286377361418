@import url(styles.css);

/* DESKTOP */

.text-body-bold-normal {
  font-family : var(--main-font-family);
  font-weight: var(--font-weight-semibold);
  font-size: 16px;  
  line-height: 20px;
}

.text-body-regular-small {
  font-family : var(--main-font-family);
  font-weight: var(--font-weight-regular);
  font-size: 14px;  
  line-height: 16px;
}

/* TODO: add other text styles when we need them */

/* TABLET */

@media screen and (min-width: 481px) and (max-width: 768px) {
  .text-body-bold-normal {
    font-size: 14px;  
    line-height: 16px;
  }
  
  .text-body-regular-small {
    font-size: 12px;  
    line-height: 16px;
  }
}

/* MOBILE */

@media screen and (max-width: 480px ){
  .text-body-bold-normal {
    font-size: 14px;  
    line-height: 16px;
  }
  
  .text-body-regular-small {
    font-size: 12px;  
    line-height: 16px;
  }
}
