@media screen and ( max-width: 375px ){


  .Image-Source.Big-Cards {
    width: 296px;
    height: 180px;
  }

  .Image-Source.Email {
    width: 296px;
    height: 180px;
  }

  .Image-Source.OpenInfo {
    width: 296px;
    height: 180px;
  }

  .Button.Header-Navigation-Button {
    width: calc(100% - 48px);
  }

  .App-Row.pb160 {
    padding-bottom: 16px !important;
  }

  .App-Col.Connect {
    width: calc(100% - 44px) !important;
    margin-top: 16px;
  }

  .Button.Navigation-Button-Page-Invert {
    width: calc(100% - 44px) !important;
  }

  .Button.Navigation-Button-Page {
    width: 100% !important;
  }

  .App-Row.p64-80 {
    padding: 24px !important;
  }

  .App-Col.Manager {
    width: calc(100% - 4px) !important
  }

  .select-css {
    width: 95%;
  }

  .dropdown {   
    position   : relative;
    user-select: none;
    z-index    : 1;
    }

  .short {
    width: 95%;
  }
  .m-pad {
    padding-bottom: 160px;
  }

  .btn-resize {
    width  : 238px !important;
    padding: 12.75px 20px;

  }
}

    