@import url(../../../styles/styles.css);

.App-Lang-Selector1 {
  position      : relative;
  display       : flex;
  flex-direction: column;
  align-items   : center;
}

.App-Lang-V1 {
  position: relative;
  display : none;
}

.App-Lang-V2 {
  position: relative;
  display : none;
}

.App-Lang-V3 {
  position: relative;
  display : none;
}

.App-Curent-Lang {
  width              : 154px;
  /* height             : 32px; */
  border             : 1px solid var(--dropdown-color-border);
  border-radius      : 5px;
  text-align         : left;
  cursor             : pointer;
  padding            : 8px 0 8px 12px;
/*    background-image   : url('../../Form/img/v1.svg');
  background-repeat  : no-repeat;
  background-position: 95% 50%;*/
  margin-top: 4px;

}

.App-CurentLang-Text {
  font-family        : var(----main-font-family);
  font-style         : normal;
  font-weight        : normal;
  font-size          : 12px;
  line-height        : 16px;
  color              : var(--dropdown-color-text);
  pointer-events: none;
}

.App-CurentLang-Img {
width: 10%;
height: 16px;
background-image   : url('../../Form/img/v1.svg');
background-repeat  : no-repeat;
margin-top: 4px;
margin-right: 12px;
pointer-events: none;
}

.arrow_down {
  background-image   : url('../../Form/img/v2.svg');
  opacity: 0.65;
  background-repeat  : no-repeat;
  margin-top: 4px;
}

.App-Lang-Variants {
  position     : relative;
  width        : 168px;
  height       : 18px;
  margin-top   : 4px;
  border       : none;
  border-radius: 5px;
  display      : none;
  background   : #ffffff21;
  padding      : 10px 0 10px 0;
  z-index      : 120;
}

.App-Lang-item {
  position     : relative;
  margin-bottom: 4px;
}



.App-Lang-item:hover {
  position: relative;
  cursor  : pointer;
  color   : var(--static-white) ;
  opacity : 1;

}

.App-Lang-Btn {
  display: block !important;
  position   : relative;
  width      : 168px;
  height     : 32px;
  padding    : 0 0 0 6px;
  border     : none   !important;
  font-family: OpenSans-Regular;
  font-style : normal;
  font-weight: normal;
  font-size  : 12px;
  line-height: 16px;
  color      : var(--static-white) ;
  opacity    : 0.5;
  cursor     : pointer;
  text-align : left;
}

.App-Lang-Btn:hover {
  position   : relative;
  width      : 168px;
  height     : 32px;
  padding    : 0 0 0 6px;
  border     : 1 solid rgb(255, 0, 0) !important;
  font-family: OpenSans-Regular;
  font-style : normal;
  font-weight: normal;
  font-size  : 12px;
  line-height: 16px;
  color  : var(--static-white) ;
  opacity: 1;
  text-align : left;
}

.show-me {
  display: block !important;
}

.hide-me {
  display:none;
}

.chevron {
  width: 20px !important;
  height: 20px !important;
  right: 11px !important;
  position: absolute !important;
  top: 11px !important;

}

.show-me1 {
  display: flex !important;
  flex-direction: row;
  justify-content: space-between;
}


