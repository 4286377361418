@import url(../src/styles/styles.css);

@font-face {
  src        : url('/src/CustomFonts/Montserrat/Montserrat-ExtraBold.ttf');
  font-family: 'Montserrat';
}

@font-face {
  src        : url('/src/CustomFonts/Montserrat/Montserrat-Bold.ttf');
  font-family: 'Montserrat-Bold';
}

@font-face {
  src        : url('/src/CustomFonts/Montserrat/Montserrat-SemiBold.ttf');
  font-family: 'Montserrat-SemiBold';
}

@font-face {
  src        : url('/src/CustomFonts/Montserrat/Montserrat-Medium.ttf');
  font-family: 'Montserrat-Medium';
}

@font-face {
  src        : url('/src/CustomFonts/Montserrat/Montserrat-Regular.ttf');
  font-family: 'Montserrat-Regular';
} 

@font-face {
  src        : url('/src/CustomFonts/Open_Sans/OpenSans-Bold.ttf');
  font-family: 'OpenSans';
}

@font-face {
  src        : url('/src/CustomFonts/Open_Sans/OpenSans-Regular.ttf');
  font-family: 'OpenSans-Regular';
}

@font-face {
  src        : url('/src/CustomFonts/SFCompactRounded/SFCompactRounded-Bold.ttf');
  font-family: 'SFCompactRounded';
}

@font-face {
  src        : url('/src/CustomFonts/SFCompactRounded/SFCompactRounded-Regular.ttf');
  font-family: 'SFCompactRounded-Regular';
}

/*---Enabled Bootstrap for Forms---*/
/* @import "bootstrap/dist/css/bootstrap.css"; */


html,body,#root,.App{
  position  : relative;
  width     : 100%;
  height    : 100%;
  padding   : 0px;
  margin    : 0px;
  background: var(--static-white);
  min-width: 340px !important;
}

.scroll-to-top {
  position : fixed;
  bottom   : 0.5rem;
  right    : 0.5rem;
  animation: fadeIn 700ms ease-in-out 1s both;
  cursor   : pointer;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}


