@import url(../../../src/styles/styles.css);

.App-Body{
  width     : 100%;
  display   : block;
  position  : relative;
  background: var(--static-white);
}

.App-Body-Content{
  width         : 1280px;
  margin        : 0 auto;
  display       : block;
  position      : relative;
  box-sizing    : border-box;
  padding-top   : 184px;
  padding-bottom: 100px;
}

.App-Row{
  width     : 100%;
  display   : table;
  position  : relative;
  box-sizing: border-box;
}

.App-Col{
  width         : 50%;
  display       : table-cell;
  vertical-align: middle;
}

.App-Col.Connect {
  width: 180px !important;
}

.App-Col.Manager {
  width: 240px !important;
  padding-right: 24px;
}

.App-Col.Manager-Question {
  padding-left: 80px !important;
}

.App-Col.Rate {
  width: 161px !important;
  display: flex !important;
}

.App-Col.inline{
  display: inline-block;
}

.App-Col.inline.center{
  display        : inline-flex;
  justify-content: center;
}

.App-Col.flex-end{
  display         : flex;
  display         : -webkit-box;
  display         : -moz-box;
  display         : -ms-flexbox;
  display         : -webkit-flex;
  -webkit-box-pack: flex-end;
  -ms-flex-pack   : flex-end;
  justify-content : flex-end;
}

.App-Col.w33{
  width: 33.33%;
}

.App-Col.rp20{
  padding-right: 20px;
}

.App-Col.pb64{
  padding-bottom: 64px;
}

.App-Col.pb32{
  padding-bottom: 32px;
}

.App-Col.rp8{
  padding-right: 8px;
}

.App-Col.lp8{
  padding-left: 8px;
}

.App-Row.br60{
  border-radius: 24px;
}

.App-Row.BG-pw{
  background: var(--additional);
}

.App-Row.pb24{
  padding-bottom: 24px;
}

.App-Row.pb64{
  padding-bottom: 64px;
}

.App-Row.pb40{
  padding-bottom: 40px;
}

.App-Row.pb0{
  padding-bottom: 0px;
}

.App-Row.pb90{
  padding-bottom: 85px;
}

.App-Row.pb160{
  padding-bottom: 160px;
}

.App-Row.p64-80{
  padding: 64px 48px;
}

.App-Row > .App-Col > .App-Row{
  padding-bottom: 24px;
}

.Tariffs-Block{
  width        : calc( 100% - 15% );
  height       : 372px;
  padding      : 40px;
  display      : block;
  position     : relative;
  background   : rgba(217, 202, 247, 0.3);
  box-sizing   : border-box;
  border-radius: 30px;
}

@keyframes transform {
  0%,
100% { border-radius: 63% 37% 54% 46% / 55% 48% 52% 45%; } 
 14% { border-radius: 40% 60% 54% 46% / 49% 60% 40% 51%; } 
 28% { border-radius: 54% 46% 38% 62% / 49% 70% 30% 51%; } 
 42% { border-radius: 61% 39% 55% 45% / 61% 38% 62% 39%; } 
 56% { border-radius: 61% 39% 67% 33% / 70% 50% 50% 30%; } 
 70% { border-radius: 50% 50% 34% 66% / 56% 68% 32% 44%; } 
 84% { border-radius: 46% 54% 50% 50% / 35% 61% 39% 65%; } 
}


@keyframes movement-t {
  0%,100% { 
    top: 0%;
  }
  50% { 
    top: 15%;
  }
}

@keyframes movement-b {
  0%,100% { 
    top: 40%;
  }
  50% { 
    top: 55%;
  }
}

@keyframes movement-f {
  0%,100% { 
    transform: translatey(0);
  }
  50% { 
    transform: translatey(20%);
  }
}

/*-------ADD S.Y.-------*/

.How-It-Work-Block {
  width     : calc( 100% - 15% );
  height    : 227px;
  display   : block;
  position  : relative;
  box-sizing: border-box;
}

.App-Col.w31{
  width : 28% !important;
  margin: 30px;
}

.Col-Num1{
position: absolute;
width   : 10%;
}

.Col-Num2{
  position    : absolute;
  width       : 90%;
  padding-left: 60px;
  }

.Col-Num3{
  position: absolute;
  width   : 20%;
}
    
.Col-Num4{
  position    : absolute;
  width       : 80%;
  padding-left: 90px;
}

/*------Form------ AD S.Y.*/

.Form-Row {
  display        : flex;
  flex-direction : row;
  justify-content: space-between;
  flex-flow      : wrap;
  align-content  : space-between;
}


.Form-Col {
  width: 50%;
}

.Form-Col.rp8 {
  width: 50%;
}

.Form-Col.lp8 {
  width: 50%;
}

.Form-Col {
  width: 50%;
}

.Form-Col.rp8c {
  width: 50%;
}

.Form-Col.lp8c {
  width: 50%;
}

.Title {
  padding-bottom: 40px;
  max-width     : 50%;
}

.Form-pad {
  padding-bottom: 40px;
}

.Form-pad14 {
  padding-bottom: 14px;
}

.Form-pad16 {
  padding-bottom: 16px;
}

.Form-pad24 {
  padding-bottom: 24px;
}

.short {
  width: 470px;
}


.form-control {
  display        : block;
  width          : 470px;
  height         : 42px;
  font-size      : 16px;
  line-height    : 20px;
  color          : var(--text-secondary);
  border-radius  : 5px;
  background     : var(--static-white);
  border         : 1px solid var(--divider);
  box-sizing     : border-box;
  border-radius  : 5px;
  text-indent    : 12px;
  background-clip: padding-box;
  appearance     : none;
  outline        : none;
}

.form-control.is-invalid {
  border: 1px solid var(--elements-error) !important;
}

.form-control.is-valid {
  border: 1px solid var(--ascent) !important;
}



.form-control::placeholder {
  font-family: var(--main-font-family) !important;
  font-style : normal;
  font-weight: normal;
  font-size  : 16px;
  line-height: 20px;
  color      : var(--elements-placeholder);
  /* padding-left: 8px; */
  margin: 0;
  padding: 0;
}

.form-control:active {
  background   : var(--static-white);
  border-radius: 5px;
  outline      : var(--ascent);
  color        : var(--text-secondary);
}

.form-control:focus {
  background   : var(--static-white);
  border-radius: 5px;
  outline      : var(--ascent);
  color        : var(--text-secondary);
}

.form-control:focus {
  border-color: none;
  box-shadow  : none;
}

.form-control.textarea {
  padding-top  : 8px;
  width        : 470px;
  height       : 116px;
  margin-bottom: 24px;
  border-radius: 5px;
  background   : var(--static-white);
  color        : var(--elements-secondary);
  box-sizing   : border-box;
  border-radius: 5px;
  text-indent  : 12px;
  margin-top   : 26.83px;
  font-family: var(--main-font-family);
  font-style : normal;
  font-weight: 300;
  font-size  : 16px;
  line-height: 20px;
  resize     : none;

}

.select-css { 
  display    : block;
  font-family: var(--main-font-family);
  font-style : normal;
  font-weight: normal;
  font-size  : 16px;
  line-height: 20px;
 
  padding            : .6em 1.4em .5em .8em; 
  width              : 100%;
  border-radius      : 5px;
  background         : var(--static-white);
  border             : 1px solid #A0A3BD;
  box-sizing         : border-box;
  border-radius      : 5px;
  -moz-appearance    : none;
  -webkit-appearance : none;
  appearance         : none;
  /* background-image   : url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'), linear-gradient(to bottom, #ffffff 0%,#e5e5e5 100%); */
  background-repeat  : no-repeat,                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          repeat;
  background-position: right .7em top 50%,                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 0 0;
  background-size    : .65em auto,                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         100%;
  width              : 470px;
  height             : 42px;
  margin-top         : 24px;
  } 

  .select-css:-ms-expand { 
    display: none; 
    font-family: SFCompactRounded-Regular; 
  }

   .select-css:hover { border-color: #A0A3BD; }
   .select-css:focus { border-color: #A0A3BD; 
   color      :rgb(34, 34, 34);
   font-family:SFCompactRounded-Regular;
   outline    :none;
  } 
   .select-css option { 
     font-weight: normal;
    } 
   *[dir="rtl"] .select-css, :root:lang(ar) .select-css, :root:lang(iw) .select-css { 
        background-position: left .7em top 50%, 0 0;
        padding            : .6em .8em .5em 1.4em;
        border             : 1px solid #A0A3BD;
  }

.FormSend {
  display      : inline-block;
  position     : relative;
  width        : 454px;
  background   : var(--background-status-success);
  padding      : 9px;
  border-radius: 4px;
  margin-bottom: 20px;
}

.message {
  margin-top: 20px;
  font-size  : 16px;
  line-height: 20px;
}

.Form_text {
  font-family: var(--main-font-family);
  font-style  : normal;
  font-weight : normal;
  font-size   : 16px;
  line-height : 20px;
  color       : var(--elements-placeholder);
  width       : 90%;
}

.hidenboy {
  width   : 470px;
  position: relative;
  opacity : 0;
  height  : 2px;
}



.dropdown {   
width      : 470px;
position   : relative;
user-select: none;
z-index    : 1;
}

.dropdown-font {
  font-family: var(--main-font-family);
  font-style  : normal;
  font-weight : normal;
  font-size   : 16px;
  line-height : 20px;
  color       : var(--elements-placeholder);
  padding-left: 10px;
}

.dropdown-font2 {
  font-family: var(--main-font-family);
  font-style  : normal;
  font-weight : normal;
  font-size   : 16px;
  line-height : 20px;
  color       : rgba(73, 80, 87, 1);
  padding-left: 10px;
}

.dropdown .dropdown-btn {
  border         : 1px solid var(--divider);
  padding        : 10px 16px 10px 4px;
  border-radius  : 5px;
  display        : flex;
  align-items    : center;
  justify-content: space-between;
  cursor         : pointer;
}

.dropdown .dropdown-content {
  border-bottom             : 1px solid var(--divider);
  border-left               : 1px solid var(--divider);
  border-right              : 1px solid var(--divider);
  position                  : absolute;
  top                       : 100%;
  left                      : 0;
  padding                   : 0;
  background-color          : var(--static-white);
  font-weight               : 500;
  color                     : var(--divider);
  width                     : calc( 100% - 2px);
  border-bottom-left-radius : 5px;
  border-bottom-right-radius: 5px;
}

.dropdown .dropdown-content .dropdown-item {
  padding      : 10px 0px 10px 0px;
  cursor       : pointer;
  transition   : all 0.2s;
  border-bottom: 1px solid #DDE2E5;
  font-family: var(--main-font-family);
  font-style   : normal;
  font-weight  : normal;
  font-size    : 16px;
  line-height  : 20px;
  color        : var(--elements-secondary);
  padding-left : 22px;
  
}

.dropdown .dropdown-content .dropdown-item:hover {
  background: var(--additional);
}

.position {
  z-index: -1;
}

.chevron {
  width : 50%;
  height: 50%;
}

.error_field {
  font-family: var(--main-font-family);
  font-style : normal;
  font-weight: normal;
  font-size  : 12px;
  line-height: 14px;
  height     : 24px;
  width      : 470px;
  color      : var(--text-error); 
}

.container {
  max-width      : 100%;
  width          : 100%;
  display        : flex;
  flex-direction : row;
  justify-content: space-between;
  flex-flow      : wrap;
  padding        : 0px;
}

.Tariffs-Blocks{
  width          : 340px;
  height         : 372px;
  padding        : 40px;
  display        : flex;
  position       : relative;
  background     : var(--additional);
  box-sizing     : border-box;
  border-radius  : 24px;
  justify-content: left;
  align-content  : flex-start;
  flex-direction : column;
}

.Tariffs-Col1 {
  width: 100%;
}

.Tariffs-Col1s {
  width : 100%;
  height: 40px;
}

.Tariffs-Col2 {
  width : 100%;
  height: 40px;
}

.mgn40 {
  margin-bottom: 40px;
}

.mg8 {
  margin-bottom: 8px;
}

.mg64 {
  margin-bottom: 64px;
}

.mg90 {
  margin-bottom: 110px;
}

.mg160 {
  margin-bottom: 160px;
}

.Label.H1 {
  display: block !important;
}


/*------------------------------------*/
.App-Cont {
  display        : flex;
  justify-content: space-between;
  flex-direction : row;
  flex-wrap      : wrap;
  padding-bottom : 80px;
}

.Cont-1 {
  width          : 30%;
  display        : flex;
  justify-content: space-between;

}

.Cont-2 {
  width          : 33%;
  display        : flex;
  justify-content: space-between;

}

.Cont-3 {
  width          : 33%;
  display        : flex;
  justify-content: space-between;

}

.Cont-4 {
  width  : 48%;
  display: inline-block;
}

.C-title {
 
  width        : 470px;
  height       : 64px;
  margin-bottom: 24px;
}

.C-text {

  width        : 470px;
  height       : 44px;
  margin-bottom: 32px;
}

.C-btn {
  height: 55px;

}

.NText {
  width: 82% !important;
}

.BigNum {
  font-size              : 150px;
  font-family            : "Montserrat-Bold";
  line-height            : 116px;
  color                  : var(--ascent-pressed);
  text-transform         : uppercase;
  background             : var(--ascent);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.NTitle {
  color       : var(--text-secondary);
  font-family : var(--main-font-family);
  font-style : normal !important;
  font-weight: 500 !important;
  font-size  : 26px !important;
  line-height: 32px !important;
  /*padding-bottom: 16px !important;*/  
}

.pad16 {
  margin-top: 16px;
}

.NPtext {
  color       : var(--text-secondary);
  font-family : var(--main-font-family);
  font-size  : 16px !important;
  line-height: 22px !important;
  font-style : normal !important;
  font-weight: normal !important;
  
}

.mr22 {
  margin-right: 22px;
}

.mr12 {
  margin-right: 12px;
}


.Button-Text.Footer-Navigation-Link.small-part:hover{
  color  : #ffffff;
  opacity: 1;
}

.Button-Text.Footer-Navigation-Link.small-part1:hover{
  color  : #ffffff;
  opacity: 1;
}

.Button-Text.Footer-Navigation-Link.f-lang:hover{
  color  : #ffffff;
  opacity: 1;
}

.H0 {
  font-family : var(--main-font-family);
  font-style : normal !important;
  font-weight: 600;
  font-size  : 12px !important;
  line-height: 16px;

/* identical to box height */
letter-spacing: 0.08em;
text-transform: uppercase;

color: #000000 !important;
}

.t-btn2 {
  top: -7px;
}

