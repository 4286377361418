@media screen and ( max-width: 1440px ){
  
  .App-Col.Manager-Question {
    padding-left: 80px !important;
  }

  .App-Header{
    padding: 24px 80px !important;
  }

  .App-Header1{
    padding: 24px 80px !important;
  }

  .App-Header-Content{
    left : 0px !important;
    width: 100% !important;
  }

  .App-Footer{
    padding: 40px 80px !important;
  }

  .Logo-Footer {
    width: calc( 1280px - 160px ) !important;
  }

  .App-Footer-Content{
    left : 0px !important;
    width: 100% !important;
  }

  .App-Body-Content{
    width: calc( 100% - 160px ) !important;
  }
}