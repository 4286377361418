@import url(../../styles/styles.css);


.Button{
  width     : 100%;
  cursor    : pointer;
  border    : solid 1px var(--ascent);
  /* padding   : 15px 0; */
  display   : inline-block;
  position  : relative;
  text-align: center;
}

.Button-Text{
  font-family        : var(--main-font-family);
  font-size          : 14pt;
  user-select        : none;
  -ms-user-select    : none;
  -moz-user-select   : none;
  -webkit-user-select: none;
}

.Button.mr24{
  margin-right: 16px;
}

.Button.Header-Logo{
  width  : auto;
  border : none;
  padding: 0px;
}

.Button.Header-Logo-Finkit{
  /* width  : auto; */
  width: 118px;
  height: 32px;
  border : none;
  padding: 0px;
  background-image: url(../../img/Finkit_Green.svg);
  background-repeat: no-repeat;
  /* background-size: 34px 84px; */
}

.Button.Footer-Logo-Finkit{
  /* width  : auto; */
  width: 96px;
  height: 36px;
  border : none;
  padding: 0px;
  background-image: url(../../img/Finkit_White.svg);
  background-repeat: no-repeat;
  /* background-size: 34px 84px; */
}

.Button-Text.Header-Logo{
  width         : auto;
  color         : var(--ascent);
  font-size     : 28px;
  font-style    : normal;
  text-align    : left;
  font-family   : 'Montserrat';
  line-height   : normal;
  font-stretch  : normal;
  letter-spacing: 3%;
}

.Button-Text.Header-Logo.whitelogo {
  color   : var(--static-white);
  position: relative;
  left    : 0px
}

.Button.Burger-Header-Logo{
  width  : auto;
  border : none;
  padding: 0px;
}

.Button-Text.Burger-Header-Logo{
  width         : auto;
  color         : var(--ascent);
  font-size     : 28px;
  font-style    : normal;
  text-align    : left;
  font-family   : 'Montserrat';
  line-height   : normal;
  font-stretch  : normal;
  letter-spacing: 3%;
}

.Button.Navigation-Button-Page-Invert{
  width        : 194px  ;
  height       : 18px;
  border       : 2px solid var(--ascent);
  padding      : 12px 24px 12px 24px;
  background   : none;
  /* box-shadow   : 0 5px 20px 0 rgba(93, 33, 209, 0.25); */
  border-radius: 8px;
}

.Button-Text.Navigation-Button-Page-Invert{
  width         : auto;
  color         : var(--ascent);
  font-size     : 16px;
  font-style    : normal;
  font-weight   : bold;
  line-height   : normal;
  font-family   : var(--main-font-family);
  font-stretch  : normal;
  letter-spacing: normal;
}


.Button.Navigation-Button-Page-Invert:hover {
  background: var(--ascent-hover) !important;
}

.Button.Navigation-Button-Page-Invert:active {
  background: var(--ascent-pressed) !important;
}

.Button.Navigation-Button-Page-Invert:hover .Button-Text {
  color: var(--static-white) !important;
}

.Button-Text.Navigation-Button-Page-Invert:active {
  color: var(--static-white) !important;
}


.Button.Navigation-Button-Page{
  width        : 137px;
  /* height       : 44px; */
  border       : none;
  padding      : 12px 24px 12px 24px;
  background   : var(--ascent);
  /* box-shadow   : 0 5px 20px 0 rgba(93, 33, 209, 0.25); */
  border-radius: 8px;
}

.Button-Text.Navigation-Button-Page{
  width         : auto;
  color         : var(--static-white);
  font-size     : 16px;
  font-style    : normal;
  font-weight   : bold;
  line-height   : normal;
  font-family   : var(--main-font-family);
  font-stretch  : normal;
  letter-spacing: normal;
}

.Button.Navigation-Button-Page:hover{
  background: var(--ascent-hover);
  /* box-shadow: 0 4px 10px 0 rgba(106, 17, 203, 0.48); */
}

.Button.Navigation-Button-Page:active{
  background: var(--ascent-pressed);
  /* box-shadow: none; */
}





.Button.Header-Navigation-Button{
  width        : 137px;
  /* height       : 44px; */
  border       : none;
  padding      : 12px 24px 12px 24px;
  background   : var(--ascent);
  /* box-shadow   : 0 5px 20px 0 rgba(93, 33, 209, 0.25); */
  border-radius: 8px;
}

.Button-Text.Header-Navigation-Button{
  width         : auto;
  color         : var(--static-white);
  font-size     : 16px;
  font-style    : normal;
  font-weight   : var(--font-weight-medium);
  line-height   : normal;
  font-family   : var(--main-font-family);
  font-stretch  : normal;
  letter-spacing: normal;
}

.Button.Header-Navigation-Button:hover{
  background: var(--ascent-hover);
  /* box-shadow: 0 4px 10px 0 rgba(106, 17, 203, 0.48); */
}

.Button.Header-Navigation-Button:active{
  background: var(--ascent-pressed);
  /* box-shadow: none; */
}







.Button.Header-Navigation-Link{
  width       : auto;
  height      : auto;
  border      : none;
  padding     : 0;
  display     : block;
  position    : relative;
  margin-right: 48px;
}

.Button-Text.Header-Navigation-Link{
  color      : var(--text-primary);
  width      : auto;
  font-size  : 16px;
  line-height: 20px;
  /* padding-top: 1px; */
  font-weight: var(--font-weight-medium) !important;
}

.Button-Text.Header-Navigation-Link:hover{
  color: var(--ascent-hover);
}

.Button-Text.Header-Navigation-Link:active{
  color: var(--ascent-pressed);
}

.Button.Burger-Header-Navigation-Link{
  width   : auto;
  height  : auto;
  border  : none;
  padding : 0;
  display : block;
  position: relative;
}

.Button-Text.Burger-Header-Navigation-Link{
  color      : var(--elements-primary);
  width      : auto;
  font-size  : 16px;
  padding-top: 1px;
  font-weight: 600;
}

.Button-Text.Burger-Header-Navigation-Link:hover{
  color: var(--ascent-hover);
}

.Button-Text.Burger-Header-Navigation-Link:active{
  color: var(--ascent-pressed);
}

.Button.Footer-Navigation-Link{
  height  : auto;
  border  : none;
  padding : 0px 24px 0px 24px;
  display : inline-block;
  position: relative;
}

.Button.Footer-Navigation-Link.Padding-Bottom{
  padding-bottom: 16px;
}

.Button-Text.Footer-Navigation-Link{
  color      : var(--text-footer);
  width      : auto;
  font-size  : 16px;
  font-weight: var(--font-weight-medium);
}

.Button-Text.Footer-Navigation-Link:hover{
  opacity: 0.5 !important;
}

.Button-Text.Footer-Navigation-Link:active{
  color: var(--ascent-pressed);
}

.Button.Footer-Logo{
  width  : auto;
  border : none;
  padding: 0px;
}

.Button-Text.Footer-Logo{
  width         : auto;
  color         : var(--static-white);
  font-size     : 28px;
  font-style    : normal;
  text-align    : left;
  font-family   : 'Montserrat';
  line-height   : normal;
  font-stretch  : normal;
  letter-spacing: 3%;
}

.Button.Alt-Button {
  width : auto;
  border: 0px;
}

.Button.Alt-Button.C-btn{
  width: auto;
}

.Button-Text.Alt-Button{
  color         : var(--ascent);
  font-size     : 16px;
  font-family   : var(--main-font-family);
  border-radius : 8px;
  font-weight   : 500;
  border        : 2px solid  var(--ascent);
}

.Button-Text.Alt-Button:hover{
  color            : var(--static-white);
  font-size        : 16px;
  font-family      : var(--main-font-family);
  border-radius    : 8px;
  font-weight      : 500;
  border           : 2px solid  var(--ascent);
  background-color : var(--ascent-hover);
}

.Button-Text.Alt-Button:active{
  color            : var(--static-white);
  font-size        : 16px;
  font-family      : var(--main-font-family);
  border-radius    : 8px;
  font-weight      : 500;
  border           : 2px solid  var(--ascent);
  background-color : var(--ascent-pressed);
  cursor           : pointer;
}

.btn.btn_primary.request {
  width: 202px !important;
}

.btn.btn_primary.request.active {
  width        : 202px !important;
  background   : var(--ascent) !important;
  border       : 1px solid var(--ascent) !important;
  cursor       : pointer;
}


.btn.btn_primary.request.disabled {
  width        : 202px !important;
  background   : var(--ascent-disabled) !important;
  border       : 1px solid var(--ascent-disabled) !important;
}

.btn.btn_primary {
  width        : 171px;
  height       : 46px;   
  outline      : none;
  background   : var(--ascent);
  border       : 1px solid var(--ascent);
  /* box-shadow   : 0px 5px 20px rgba(93, 33, 209, 0.25); */
  border-radius: 8px;
  font-family   : var(--main-font-family);
  font-style : normal;
  font-weight: bold;
  font-size  : 17px;
  line-height: 23px;
  color      : var(--static-white);
}

.btn.btn_primary.request.active:hover {
  cursor       : pointer;
  width        : 171px;
  height       : 46px;
  background   : var(--ascent-hover) !important;
  /* box-shadow   : 0px 4px 10px rgba(106, 17, 203, 0.48); */
  border-radius: 8px;
  font-family   : var(--main-font-family);
  font-style : normal;
  font-weight: bold;
  font-size  : 17px;
  line-height: 23px;
  color      : var(--static-white) !important;
}


.btn.btn_primary:hover {
  width        : 171px;
  height       : 46px;
  background   : var(--ascent-hover);
  /* box-shadow   : 0px 4px 10px rgba(106, 17, 203, 0.48); */
  border-radius: 8px;
  font-family   : var(--main-font-family);
  font-style : normal;
  font-weight: bold;
  font-size  : 17px;
  line-height: 23px;
  color      : var(--static-white);
}

.btn.btn_primary.request.active:active {
  cursor       : pointer;
  width        : 171px;
  height       : 46px;
  background   : var(--ascent-pressed) !important;
  border       : 1px solid var(--ascent-pressed) !important;
  border-radius: 8px;
  
  font-family : var(--main-font-family);
  font-style : normal;
  font-weight: bold;
  font-size  : 17px;
  line-height: 23px;
  color      : var(--static-white);
}

.btn.btn_primary:active {
  cursor       : pointer;
  width        : 171px;
  height       : 46px;
  background   : var(--ascent-pressed);
  border       : 1px solid var(--ascent-pressed);
  border-radius: 8px;
  
  font-family : var(--main-font-family);
  font-style : normal;
  font-weight: bold;
  font-size  : 17px;
  line-height: 23px;
  color      : var(--static-white);
}

.btn-pad {
  padding: 12.75px 20px 12.75px 20px;
  left   : -20px;
}

.btn-pad2 {
  padding: 12.75px 20px 12.75px 20px;
  left   : -20px
}

.Button.Alt2-Button {
  width: 220px !important;
  border: 0px;
}

.Button-Text.Alt2-Button{
  color         : var(--static-white);
  font-size     : 17px;
  font-family   : var(--main-font-family);
  border-radius : 8px;
  background    : var(--ascent);
  height        : 55px;
}

.Button-Text.Alt2-Button:hover{
  color         : var(--static-white);
  font-size     : 17px;
  font-family   : var(--main-font-family);
  border-radius : 8px;
  background    : var(--ascent-hover);
}

.Button-Text.Alt2-Button:active{
  color         : var(--static-white);
  font-size     : 17px;
  font-family   : var(--main-font-family);
  border-radius : 8px;
  background    : var(--ascent-pressed);
}

.btn-resize {
  width  : 252px !important;
  height : 44px !important;
}