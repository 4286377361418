.Burger{
  width   : 44px;
  height  : 44px;
  z-index : 2;
  display : none;
  position: fixed;
  z-index : 50;
}

.Burger-Icon{
  width   : 100%;
  height  : 100%;
  cursor  : pointer;
  position: relative;
}

.Burger-Icon.Show{ 
  background     : url(./Images/Menu_burger.png);
  background-size: 44px;
}

.Burger-Icon.Close{
  left           : 80px;
  position       : absolute;
  background     : url(./Images/Menu_close.png);
  background-size: 44px;
}

.Burger-Items-Wrapper{
  top           : 0px;
  left          : 0px;
  width         : 100%;
  display       : none;
  position      : fixed;
  background    : var(--static-white);
  box-shadow    : 0 4px 16px 0 rgba(0, 0, 0, 0.11);
  border-radius : 0 0 25px 25px;
  padding-bottom: 32px;
  z-index       : 50;
}

.Burger-Items-Wrapper.visible{
  display: block;
}

.Burger-blur {
  width                  : 100%;
  height                 : 2000px;
  position               : fixed;
  top                    : 0px;
  left                   : 0px;
  z-index                : -20;
  background-color       : rgba(180, 179, 179, 0.5);
  display                : none;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter        : blur(3px);
}
.Burger-blur.visible {
  display: block !important;
}

.Burger-Content-Header{
  width          : 100%;
  display        : inline-flex;
  padding        : 34px 80px 31px;
  position       : relative;
  box-sizing     : border-box;
  align-items    : center;
  justify-content: center;
}

.Burger-Delimiter{
  width     : 332px;
  height    : 1px;
  margin    : 20px 0px;
  background: #dde2e5;
}

.Burger-Content-Row{
  width          : 100%;
  display        : inline-flex;
  padding        : 12px 40px;
  position       : relative;
  box-sizing     : border-box;
  align-items    : center;
  justify-content: center;
}
