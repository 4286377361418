@media screen and ( max-width: 1200px ){

  .App-Col.Manager-Question {
    padding-left: 40px !important;
  }

  .Label-Text.H1{
    font-size: 40px !important;
  }

  .Label-Text.H4{
    font-size: 18px !important;
  }

  .Label-Text.H2{
    font-size: 34px !important;
  }

  .Label-Text.H5{
    font-size: 13px !important;
  } 

  .App-Col.App-Col.w33[name=triple]:nth-child(3){
    width: 100% !important;
  }

  .App-Col.App-Col.w33[name=triple]:nth-child(3) > .Tariffs-Block{
    width: 100% !important;
  }

  .App-Col.App-Col.w33[name=triple]:nth-child( -n + 2 ){
    width: calc(50% - 32px) !important;
  }

  .App-Col.App-Col.w33[name=triple]:nth-child( -n + 2 ) > .Tariffs-Block{
    width: 100%;
  }

  .App-Col.App-Col.w33[name=triple]:nth-child( 1 ) {
    padding-right: 64px;
  }

  .Image.About{
    width : 380px !important;
    height: 350px !important;
  }

  .App-Col.inline[name="dynamic"]{
    width          : 100% !important;
    padding-bottom : 40px !important;
    justify-content: left !important;
  }

  .App-Col.inline.center[name="dynamic"]{
    padding-bottom: 0px !important;
  }

  .App-Col.flex-end{
    width          : auto !important;
    justify-content: left !important;
  }
  
  .Form-Col.rp8c {
    width: 50%;
  }

  .Form-Col.lp8c {
    width: 50%;
  }

  .Form-Col.rp8 {
    width: 56%;
  }
  
  .Form-Col.lp8 {
    width: 44%;
  }

  .col-4 {
    width          : 48% !important;
    display        : flex;
    flex-direction : row;
    justify-content: space-between;
    flex-flow      : wrap;
    padding-bottom : 64px;
  }

  .col-4:nth-child(3) {
    width          : 100% !important;
    display        : flex;
    flex-direction : row;
    justify-content: space-between;
    flex-flow      : wrap;
    padding-bottom : 64px;
  }

  .Tariffs-Blocks{
    width: 100%;
  }

  .Tariffs-Col2 {
    width: 50%;
  }

  .order1 {
    order: 1;
  }
  .order2 {
    order: 3;
  }

  .order3 {
    order       : 2;
    padding-top : 80px !important;
    padding-left: 56px !important;
  }

  .Label-Text.H1.t3h1{
    font-size: 32px !important;
  }

}
