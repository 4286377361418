@media screen and ( max-width: 430px ){

  .cth {
    height: 56px;
  }

  .cth2 {
    height        : 56px;
    padding-bottom: 20px;
  }

  .C-btn {
    height: 54px;
  }

  .Image-Source.Big-Cards {
    width: 320px;
    height: 200px;
    min-height: 0px !important;
  }

  .Image-Source.Lot-Of-Staff {
    width: 320px;
    height: 200px;
    min-height: 0px !important;
  }

  .Image-Source.API {
    width: 320px;
    height: 200px;
    min-height: 0px !important;
  }

  .Image-Source.Box_With_Cards {
    width: 320px;
    height: 192px;
    min-height: 0px !important;
  }

  .Image-Source.Custom-Page {
    width: 320px;
    height: 176px;
    min-height: 0px !important;
  }
  
  .Image-Source.All-Settings {
    width: 320px;
    height: 176px;
    min-height: 0px !important;
  }
  
  .Image-Source.Devs {
    width: 320px;
    height: 200px;
    min-height: 0px !important;
  }
  
  .Image-Source.About {
    width: 320px;
    height: 176px;
    min-height: 0px !important;
  }

  .File-Button {
    height: 80px !important;
  }
  
  .File-Button-Title-Wrapper {
    height: 40px;
  }

  .File-Button-Text {
    width: 100% !important;
  }

}