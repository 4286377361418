@import url(../../../src/styles/styles.css);

.Label{
  width     : 100%;
  display   : block;
  position  : relative;
  box-sizing: border-box;
}

.Label-Text.Mail {
  cursor: pointer;
}

.Label-Text.Tel {
  cursor: pointer;
}

.Label.Checkout-Title{
  padding: 0px;
}

.Label-Text{
  width    : 100%;
  color    : var(--text-primary);
  position : relative;
  font-size: 14pt;
}

.Label-Text.H1{
  color       : var(--text-primary);
  word-break  : none;
  white-space : break-spaces;
  word-wrap   : none;
  font-size   : 50px;
  /* font-style  : normal; */
  /* line-height : 64px; */
  font-weight : 300;
  font-family : var(--main-font-family);
  /* font-stretch: normal; */
}

.Label-Text.H2{
  color          : var(--text-primary);
  font-family    : var(--main-font-family);
  font-size      : 38px;
  font-weight    : 300;
  font-stretch   : normal;
  font-style     : normal;
  line-height    : 40px;
  letter-spacing : normal;
}

.Label-Text.H3G{
  color          : var(--ascent);
  font-family    : var(--main-font-family);
  font-size      : 26px;
  font-style     : normal;
  font-weight    : 300;
  line-height    : 32px;
  font-stretch   : normal;
  letter-spacing : normal;
}

.Label-Text.H3{
  color          : var(--text-primary);
  font-family    : var(--main-font-family);
  font-size      : 26px;
  font-style     : normal;
  font-weight    : 300;
  line-height    : 32px;
  font-stretch   : normal;
  letter-spacing : normal;
}

.Label-Text.H320{
  padding-top    : 16px !important;
  color          : var(--text-primary);
  font-family    : var(--main-font-family);
  font-size      : 16px !important;
  font-style     : normal !important;
  font-weight    : 300 !important;
  line-height    : 20px !important;
  font-stretch   : normal !important;
  letter-spacing : normal !important;
}

.Label-Text.H322{
  padding-top    : 16px !important;
  color          : var(--text-primary);
  font-family    : var(--main-font-family);
  font-size      : 22px !important;
  font-style     : normal !important;
  font-weight    : 500 !important;
  line-height    : 32px !important;
  font-stretch   : normal !important;
  letter-spacing : normal !important;
}

.Label-Text.H4G{
  color          : var(--ascent);
  font-family    : var(--main-font-family);
  font-size      : 20px;
  font-weight    : 400;
  font-stretch   : normal;
  font-style     : normal;
  line-height    : 24px;
  letter-spacing : normal;
}

.Label-Text.H4{
  color          : var(--text-secondary);
  font-family    : var(--main-font-family);
  font-size      : 20px;
  font-weight    : 400;
  font-stretch   : normal;
  font-style     : normal;
  line-height    : 24px;
  letter-spacing : normal;
}

.Label-Text.H5{
  color          : var(--text-secondary);
  font-family    : var(--main-font-family);
  font-size      : 12px;
  font-weight    : 400;
  font-stretch   : normal;
  font-style     : normal;
  line-height    : 15px;
  letter-spacing : 0.08em;
  text-transform : uppercase;
}

.Label-Text.P{
  color       : var(--text-secondary);
  font-family : var(--main-font-family);
  font-size   : 16px;
  line-height : 20px;
  font-style  : normal;
  font-weight : normal;
}

.Label-Text.H1.p24{
  font-size: 24px;
}

.Break{
  width: 100%;
}

.Bold-Font{
  color       : var(--text-secondary);
  font-family : var(--main-font-family);
  font-size     : 24px;
  font-weight   : bold;
  font-stretch  : normal;
  font-style    : normal;
  line-height   : 1.3;
  letter-spacing: normal;
  text-align    : left;
}

.Custom-OpenSans{
  color       : var(--text-secondary);
  font-family : var(--main-font-family);
  font-size     : 16px;
  font-weight   : normal;
  font-stretch  : normal;
  font-style    : normal;
  line-height   : normal;
  letter-spacing: normal;
  text-align    : left;
}

.Label.Center{
  text-align: center;
}

.Green{
  color: var(--ascent) !important;
}

.Num-Text{
  color         : var(--text-secondary);
  font-family   : var(--main-font-family);
  font-size     : 16px;
  font-weight   : normal;
  font-stretch  : normal;
  font-style    : normal;
  line-height   : normal;
  letter-spacing: normal;
  text-align    : left;
}

.H3N{
  color         : var(--text-secondary);
  font-family   : var(--main-font-family);
  font-size     : 21px;
  font-weight   : 500;
  font-stretch  : normal;
  font-style    : normal;
  line-height   : 26px;
  letter-spacing: normal;
  padding-bottom: 8px;
}

.H3N17{
  color         : var(--text-secondary);
  font-family   : var(--main-font-family);
  font-size     : 17px !important;
  font-weight   : 500 !important;
  font-stretch  : normal !important;
  font-style    : normal !important;
  line-height   : 26px !important;
  letter-spacing: normal !important;
  padding-bottom: 8px !important;
}

.H3F {
  color       : var(--text-secondary);
  font-family : var(--main-font-family);
  font-style : normal;
  font-weight: 500;
  font-size  : 26px;
  line-height: 32px;
}