@media screen and ( max-width: 768px ){

  .Image.Big-Cards {
    left  : 0px !important;
    width : 100% !important;
    height: 224px !important;
  }

  .Image.Lot-Of-Staff {
    left  : 0px !important;
    width : 100% !important;
    height: 224px !important;
  }

  .Image.API {
    left  : 0px !important;
    width : 285px !important;
    height: 224px !important;
  }

  .Image.Info {
    width : 274px !important;
    height: 224px !important;
  }

  .Image.Box_With_Cards{
    left  : 0px !important;
    width : 100% !important;
    height: 224px !important;
  }

  .Image.Personal-Area{
    left  : 0px !important;
    width : 100% !important;
    height: 224px !important;
  }

  .Image.Custom-Page{
    left  : 0px !important;
    width : 100% !important;
    height: 224px !important;
  }

  .Image.All-Settings{
    left  : 0px !important;
    width : 100% !important;
    height: 224px !important;
  }

  .Image.Devs{
    left  : 0px !important;
    width : 100% !important;
    height: 350px !important;
  }

  .Image.About{
    left  : 0px !important;
    width : 100% !important;
    height: 234px !important;
  }

  .Image.Email{
    left  : 0px !important;
    width : 100% !important;
    height: 234px !important;
  }

  .Image.OpenInfo{
    left  : 0px !important;
    width : 100% !important;
    height: 240px !important;
  }

  .App-Row.Manager-Question {
    display: block !important;
  }

  .App-Col.Manager-Question {
    width: 100% !important;
    padding-top: 24px !important;
    padding-left: 0px !important;
    display: block !important;
  }
  
  .App-Row.pb160 {
    padding-bottom: 40px !important;
  }

  .Burger-Content-Header {
    padding: 32px 24px 20px 32px !important;
    justify-content: flex-end !important;
  }
  
  .App-Header{
    padding: 34px 24px !important;
  }

  .App-Header1{
    padding: 34px 24px !important;
  }

  .Label-Text.H2{
    font-size  : 30px !important;
    line-height: 37px !important;
  }

  .Label-Text.H4 {
    font-size: 14px !important;
    line-height: 20px !important;
    font-weight: 400 !important;
  }

  .Label-Text.H1 {
    font-size: 32px !important;
    line-height: 40px !important;
    font-weight: 300 !important;
  }

  .Label-Text.P {
    font-size: 14px !important;
    line-height: 16px !important;
    font-weight: 400 !important;
  }


  .App-Body-Content{
    width: calc( 100% - 48px ) !important;
    padding-top: 120px !important;
    padding-bottom: 16px !important;
  }

  .Burger{
    left: 24px !important;
  }

  .Burger-Icon.Close{
    left: 24px !important;
  }

  .App-Footer{
    padding: 40px 24px !important;
  }

  .App-Footer-Links{
    width: 100% !important;
  }

  .Button-Text.Footer-Navigation-Link{
    font-size: 16px !important;
  }

  .App-Footer-Langs{
    position: relative !important;
  }

  .App-Footer-Links{
    width        : 100% !important;
    margin-bottom: 64px !important;
  }

  .App-Col.w33{
    width     : 50% !important;
    text-align: left;
  }

  .Image.Big-Icon{
    margin: none;
  }

  .Label-Text.H5{
    font-size: 14px !important;
  }

  .App-Footer-Logo{
    width: calc( 100% - 177px ) !important;
  }

  .App-Col.App-Col.w31 {
    width : 50% !important;
    margin: 0;
  }
  
  .resize-o {
    width  : 100% !important;
    display: inline-block !important;
  }

  .resize-o1 {
    width  : 100% !important;
    display: table-footer-group !important;
  }
  
  .move-a {
    width: 100% !important;
  }

  .Form-Row {
    display        : flex;
    flex-direction : row;
    justify-content: left;
    flex-flow      : wrap;
    align-content  : space-between;
  }

  .Form-Col.rp8 {
    width: 100%;
    order: 2;
  }
  
  .Form-Div {
    width: 100% !important;
    overflow-x: hidden !important; 
  }

  input {
    box-sizing: border-box;
  }

  .Form-Col.lp8 {
    width: 100%;
    order: 1;
  }

  .Form-Col.rp8c {
    width: 100%;
  }

  .Form-Col.lp8c {
    width: 100%;
  }

  .form-control {
    width: 100% !important;
  }

  .form-control.textarea {
    width: 100%;
  }

  .select-css {
    width: 525px;
  }

  .FormSend {
    width: calc(100% - 16px) !important;
  }

  .short {
    width: 525px ;
  }
  
  .dropdown {   
    width      : 100%;
    position   : relative;
    user-select: none;
    z-index    : 1;
  }

  .NPtext {
    color       : var(--text-primary) !important;
    font-family : var(--main-font-family) !important;
    font-size   : 14px !important;
    line-height : 22px !important;
    font-style  : normal !important;
    font-weight : normal !important;
  }
  .NTitle {
    color       : var(--text-secondary) !important;
    font-family : var(--main-font-family) !important;
    font-style  : normal !important;
    font-weight : 500 !important;
    font-size   : 24px !important;
    line-height : 32px !important;
  }

  .Cont-1 {
    width          : 100%;
    display        : flex;
    justify-content: left;
    flex-direction : row;
    flex-wrap      : nowrap;
    padding-bottom : 32px;
  }
  
  .Cont-2 {
    width          : 100%;
    display        : flex;
    flex-direction : row;
    justify-content: left;
    flex-wrap      : nowrap;
    padding-bottom : 32px;
  }
  
  .Cont-3 {
    width          : 100%;
    display        : flex;
    justify-content: left;
    margin         : 0;
    flex-direction : row;
    flex-wrap      : nowrap;
  }

  .App-Cont {
    padding-bottom: 64px;
  }

  .C-title {

    width        : 344px;
    height       : 58px;
    margin-bottom: 24px;
  }
  
  .C-text {

    width        : 344px;
    height       : 66px;
    margin-bottom: 32px;
  }

}
