@import url(../../../src/styles/styles.css);

.App-Footer{
  position  : relative;
  width     : 100%;
  display   : block;
  padding   : 40px 0;
  position  : relative;
  background: var(--additional-dark);
  box-sizing: border-box;
  z-index   : 1;
}

.App-Footer-Content{
  left    : calc( 50% - 640px );
  width   : 1280px;
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
}

.App-Footer-Content .Footer-Column-Left {
  position: relative;
  width: 90%;
  height: auto;
  display: block;
  z-index: 2;
}
.App-Footer-Content .Footer-Column-Right {
  position: relative;
  width: 10%;
  height: 10%;
  display: block;
  z-index: 12;
}


.App-Footer-Links{
  width        : auto;
  display      : inline-block;
  position     : relative;
  margin-bottom: 42px;
}

.Footer-Menu {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin: 42px 0 40px 0;
}


.Footer-Icons {
  display        : flex;
  justify-content: flex-start;
  flex-direction : row;
  flex-wrap      : wrap;
  width          : 100%;
  order          : 1;
}

.Footer-Icons .text {
  font-family  : var(--main-font-family);
  font-style   : normal;
  font-weight  : 400;
  font-size    : 12px;
  line-height  : 14px;
  color        : var(--text-footer-icon);
  width        : 100%;
  margin-bottom: 8px;
}

.Footer-Icons .icons {
  display        : flex;
  justify-content: flex-start;
  width          : auto;
}

.Footer-Language {
  position: relative;
  height: 140px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.small-part {
  width       : auto;
  margin-right: 16px !important;
  text-align  : left !important;
}

.f-lang {
  text-align: left;

}

.logo-size {
  width: 20%;
}

.Language-Select {
  position      : relative;
  width         : auto;
  display       : flex;
  flex-direction: column;
  flex-wrap     : wrap;
  align-items   : flex-end;
  left          : calc( 50% - 640px );
  z-index       : 100;
}

.P-White {
  width      : 168px;
  font-family  : var(--main-font-family);
  font-style : normal;
  font-weight: normal;
  font-size  : 12px;
  line-height: 16px;
  color      : var(--text-footer);
}

.P-White-Footer {
  width      : 200px;
  font-family  : var(--main-font-family);
  font-style : normal;
  font-weight: normal;
  font-size  : 12px;
  line-height: 16px;
  color      : var(--text-footer);
}

.close_dropdown {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
  top: 0;
  left: 0 !important;
  display: none;

}

.LangForm-index {
  position: relative;
  z-index: 10;
}

.show-me {
  display: block !important;
}