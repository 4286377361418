.Image{
  width            : 80px;
  height           : 80px;
  margin           : 0 auto;
  display          : block;
  position         : relative;
  z-index          : 1;
  transform        : translateZ(2px);
  -webkit-transform: translateZ(2px);
}

.Image-Source{
  width     : 100%;
  height    : 100%;
  display   : block;
  position  : relative;
  background: #222;
}

.Image.Big-Cards{
  width : 632px;
  height: 350px;
}

.Image-Source.Big-Cards{
  background     : url(./Images/cards.png );
  background-size: 100%;
  background-repeat: no-repeat;
}

.Image.Lot-Of-Staff{
  width : 632px;
  height: 350px;
}

.Image-Source.Lot-Of-Staff{
  background       : url( ./Images/lot_of_staff.png );
  background-size  : 100%;
  background-repeat: no-repeat;
}

.Image.Info{
  width : 632px;
  height: 350px;
}

.Image-Source.Info{
  background           : url( ./Images/Info.png );
  background-size      : 100%;
  background-repeat    : no-repeat;
}

.Image.API{
  width : 632px;
  height: 350px;
}

.Image-Source.API{
  background       : url( ./Images/API.png );
  background-size  : 100%;
  background-repeat: no-repeat;
}

.Image.Big-Icon{
  width : 80px;
  height: 80px;    
  margin: 0px;
  margin-bottom: 8px;
}

.Image-Source.Big-Icon.ThreeDS {
  background     : url( ./Images/3DS.png );
  background-size: 100%;
}

.Image-Source.Big-Icon.SSL {
  background     : url( ./Images/SSL.png ) ;
  background-size: 100%;
}

.Image-Source.Big-Icon.HTTPS {
  background     : url( ./Images/HTTPS.png ) ;
  background-size: 100%;
}

.Image-Source.Big-Icon.Visa {
  background     : url( ./Images/VISA.png ) ;
  background-size: 100%;
}

.Image-Source.Big-Icon.MasterCard {
  background     : url( ./Images/MasterCard.png ) ;
  background-size: 100%;
}

.Image-Source.Big-Icon.PCI-DSS {
  background     : url( ./Images/PSI-DSS.png ) ;
  background-size: 100%;
}


.Image.Box_With_Cards{
  width : 632px;
  height: 350px;
}

.Image-Source.Box_With_Cards{
  background     : url(./Images/box_with_cards.png );
  background-size: 100%;
  background-repeat: no-repeat;
}

.Image.Personal-Area{
  width : 632px;
  height: 350px;
}

.Image-Source.Personal-Area{
  background     : url(./Images/personal-area.png );
  background-size: 100%;
  background-repeat: no-repeat;
}

.Image.Custom-Page{
  width : 632px;
  height: 350px;
}

.Image-Source.Custom-Page{
  background     : url(./Images/custom-page.png );
  background-size: 100%;
  background-repeat: no-repeat;
}

.Image.All-Settings{
  width : 632px;
  height: 350px;
}

.Image-Source.All-Settings{
  background     : url(./Images/all-settings.png );
  background-size: 100%;
  background-repeat: no-repeat;
}

.Image.Devs{
  width : 632px;
  height: 350px;
}

.Image-Source.Devs{
  background       : url(./Images/devs.png );
  background-size  : 100%;
  background-repeat: no-repeat;
}

.Image.About{
  width : 632px;
  height: 350px;
}

.Image-Source.About{
  background     : url(./Images/about.png );
  background-size: 100%;
  background-repeat: no-repeat;
}

.Image.Email{
  width : 632px;
  height: 350px;
}

.Image-Source.Email{
  background     : url(./Images/email.png );
  background-size: 100%;
  background-repeat: no-repeat;
}

.Image.OpenInfo{
  width : 632px;
  height: 350px;
}

.Image-Source.OpenInfo{
  background     : url(./Images/open_info.png ), no-repeat;
  background-size: 100%;
}


/* Footer images */

.Image.pci_dss_footer{
  width : 62px;
  height: 24px;
}

.Image-Source.pci_dss_footer{
  background     : url(./Images/pci_dss.svg ), no-repeat;
  background-size: 100%;
}

.Image.mc_footer{
  width : 40px;
  height: 24px;
  margin: 0 24px;
}

.Image-Source.mc_footer{
  background     : url(./Images/mc.svg ), no-repeat;
  background-size: 100%;
}

.Image.visa_footer{
  width : 60px;
  height: 24px;
}

.Image-Source.visa_footer{
  background     : url(./Images/visa.svg ), no-repeat;
  background-size: 100%;
}

@media screen and ( max-width: 705px ){

  .Image-Source.Big-Cards {
    width: 400px;
    height: 250px;
    background     : url(./Images/cards.png ) no-repeat center center !important;
    background-size: cover !important;
    min-height: 200px !important;
  }

  .Image-Source.Lot-Of-Staff {
    width: 400px;
    height: 250px;
    background     : url(./Images/lot_of_staff.png ) no-repeat center center !important;
    background-size: cover !important;
    min-height: 200px !important;
  }

  .Image-Source.Info {
    width: 400px;
    height: 250px;
    background     : url(./Images/Info.png ) no-repeat center center !important;
    background-size: cover !important;
    min-height: 200px !important;
  }

  .Image-Source.API {
    width: 400px;
    height: 250px;
    background     : url(./Images/API.png ) no-repeat center center !important;
    background-size: cover !important;
    min-height: 200px !important;
  }

  .Image-Source.Box_With_Cards {
    width: 400px;
    height: 240px;
    background     : url(./Images/box_with_cards.png ) no-repeat center center !important;
    background-size: cover !important;
    min-height: 200px !important;
  }

  .Image-Source.Personal-Area {
    width: 400px;
    height: 250px;
    background     : url(./Images/personal-area.png ) no-repeat center center !important;
    background-size: cover !important;
    min-height: 200px !important;
  }

  .Image-Source.Custom-Page {
    width: 440px;
    height: 240px;
    background     : url(./Images/custom-page.png ) no-repeat center center !important;
    background-size: cover !important;
    min-height: 200px !important;
  }

  .Image-Source.All-Settings {
    width: 440px;
    height: 240px;
    background     : url(./Images/all-settings.png ) no-repeat center center !important;
    background-size: cover !important;
    min-height: 200px !important;
  }

  .Image-Source.Devs {
    width: 400px;
    height: 250px;
    background     : url(./Images/devs.png ) no-repeat center center !important;
    background-size: cover !important;
    min-height: 200px !important;
  }

  .Image-Source.About {
    width: 410px;
    height: 250px;
    background     : url(./Images/about.png ) no-repeat center center !important;
    background-size: cover !important;
    min-height: 200px !important;
  }

  .Image-Source.Email {
    width: 400px;
    height: 250px;
    background     : url(./Images/email.png ) no-repeat center center !important;
    background-size: cover !important;
    min-height: 200px !important;
  }

  .Image-Source.OpenInfo {
    width: 416px;
    height: 250px;
    background     : url(./Images/open_info.png ) no-repeat center center !important;
    background-size: cover !important;
    min-height: 200px !important;
  }
}