@media screen and ( max-width: 1140px ){

  .App-Col.Manager-Question {
    padding-left: 0px !important;
  }

 .Form-Col.rp8c {
   width: 57%;
 }

 .Form-Col.lp8c {
   width: 43%;
 }

 .Form-Col.rp8 {
   width: 56%;
  }
  .Form-Col.lp8 {
   width: 44%;
  }

  .Cont-1 {
    width          : 44%;
    display        : flex;
    justify-content: space-between;
  
  }
  
  .Cont-2 {
    width          : 50%;
    display        : flex;
    justify-content: space-between;
   
  }
  
  .Cont-3 {
    width          : 50%;
    display        : flex;
    justify-content: space-between;
    margin         : 40px auto 0 auto;
  
  }

  .C-title {
     width        : 400px;
     height       : 64px;
     margin-bottom: 24px;
  }
  
  .C-text {
 
    width        : 400px;
    height       : 64px;
    margin-bottom: 32px;
  }
  
}