@media screen and ( max-width: 576px ){

  .App-Col.Manager {
    width: calc(100% - 4px) !important
  }

  .App-Col.Connect {
    width: calc(100% - 44px) !important;
    margin-top: 16px;
  }

  .Button.Navigation-Button-Page-Invert {
    width: calc(100% - 44px) !important;
  }

  .Button.Navigation-Button-Page {
    width: 100% !important;
  }

  .Image{
    left  : calc( 50% - 120px);
    margin: unset !important;
  }
  
  .Image.Big-Icon{
    left  : unset !important;
    /* margin: 0 auto !important; */
  }

  .Image-Source.Info{
    background-position-y: unset !important;
  }

  .Form-Col.pp8 {
    width: 100%;
  }

  .Form-Col.rp8c {
    width: 100%;
  }
  
  .Form-Col.lp8 {
    width: 100%;
    order: 1;
  }

  .Form-Col.lp8c {
    width: 100%;
  }

  .select-css {
    width: 100%;
  }

  /* .FormSend {
    width: 95%;
  } */

  .short {
    width: 100%;
  }
  
  .dropdown {   
    position   : relative;
    user-select: none;
    z-index    : 1;
  }
      
    /* .m-pad {
      justify-content: left;
      flex-direction : column;
      flex-wrap      : wrap;
      } */
  .Cont-4 {
    margin-top   : 40px;
    width        : 100%;
    height       : 150px;
    display      : flex;
    flex-wrap    : wrap;
    align-content: space-between !important;
  }  
      

  .App-Footer-Content{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-around;
  }

  .App-Footer-Content .Footer-Column-Left {
    position: relative;
    width: 100%;
    height: auto;
    display: block;
    margin-bottom: 52px;
  }
  .App-Footer-Content .Footer-Column-Right {
    position: relative;
    width: 10%;
    height: auto;
    display: block;
  }
  .Image.pci_dss_footer {
    left: 0px !important;
    margin-right: 24px !important ;
  }

  .Image.mc_footer {
    left: 0px !important;
    margin-right: 24px !important ;
  }

  .Image.visa_footer {
    left: 0px !important;        
  }

  .Footer-Language {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
      
}
  