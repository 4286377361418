@import url(../../../src/styles/styles.css);

.File-Button-Icon {
  background     : url(./icons/file.png ), no-repeat;
  background-size: 100%;
  width : 32px;
  height: 32px;
  margin: 22px;
}

.File-Button-Icon-Arrow {
  background     : url(./icons/arrow.png ), no-repeat;
  background-size: 100%;
  width : 24px;
  height: 24px;
  margin-top: 2px;
}

.File-Button {
  border: 1px solid var(--additional);
  background-color: var(--additional);
  border-radius: 16px;
  height: 76px;
  margin-bottom: 20px;
  display: flex;
}

.File-Button-Text {
  width: 320px;
  align-content: center;
  cursor: pointer;
  color: var(--text-primary);    
  text-decoration: underline;
  text-decoration-color: var(--text-primary);
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}

.File-Button-Divider {
  border-left: 1px solid var(--divider); 
  height: 44px; 
  margin-top: 16px;
  padding-right: 16px;
}

.File-Button-View-Wrapper {
  display: flex;
  cursor: pointer;
  padding-left: 20px;
  width: 180px;
  justify-content: flex-end;
}

.File-Button-Text-Show {
  align-content: center;
  color: var(--ascent);
  font-weight: 700;
}

.File-Button-Title-Wrapper {
  display: flex;
  align-items: center;
}

.File-Button-Press-Wrapper {
  display: flex;
}

.File-Button-Press-Wrapper, .File-Button-Title-Wrapper {
  align-items: center;
}