@media screen and ( max-width: 480px ){

  .Image-Source.Box_With_Cards {
    width: 360px;
    height: 216px;
    min-height: 0px !important;
  }

  .Image-Source.Custom-Page {
    width: 360px;
    height: 200px;
    min-height: 0px !important;
  }
  
  .Image-Source.All-Settings {
    width: 360px;
    height: 200px;
    min-height: 0px !important;
  }
  
  .Image-Source.Devs {
    width: 360px;
    height: 216px;
    min-height: 0px !important;
  }
  
  .Image-Source.About {
    width: 360px;
    height: 216px;
    min-height: 0px !important;
  }
  
  .Image-Source.About {
    width: 360px;
    height: 216px;
    min-height: 0px !important;
  }
  
  .Image-Source.Email {
    width: 360px;
    height: 216px;
    min-height: 0px !important;
  }
  
  .Image-Source.OpenInfo {
    width: 360px;
    height: 216px;
    min-height: 0px !important;
  }

  .Image{
    left  : calc( 50% - 120px);
    margin: unset !important;
  }

  .Image.Big-Icon{
    left  : unset !important;
    /* margin: 0 auto !important; */
  }

  .Image-Source.Info{
    background-position-y: unset !important;
  }

  .select-css {
    width: 340px;
  }
  
  .dropdown {   
    position   : relative;
    user-select: none;
    z-index    : 1;
  }

  .short {
    width: 340px;
  }

  .m-pad {
    padding-bottom: 120px;
  }

  .small-part {
    width       : 50%;
    margin-right: 0px !important;
    text-align  : left !important;
    height      : 72px;
  }

}