@import url(../../../src/styles/styles.css);

.support-button__container {
  display: flex;
  border: none;
  border-radius: 16px;
  padding-left: 8px;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  background: var(--static-white);
  box-shadow: 0px 0px 16px rgba(0, 55, 25, 0.25);
  box-sizing: border-box;         /* the height property includes the content, padding, and border */
  height: 64px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor    : pointer;
}

.support-button__container:hover {
  background: var(--additional-light-2);
}

.support-button__text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
}

.support-button__telegram-on-icon {
  background-image: url(./icons/telegram_on.svg);
  background-repeat: no-repeat;
  background-size: 100%;
  width : 48px;
  height: 48px;
  margin-right: 8px;
}

#support-button__title-id {
  color: var(--text-secondary);
}

#support-button__subtitle-id {
  color: var(--text-secondary);    
}

/* Settings for tablets AND mobile devices */

@media screen and (max-width: 768px) {

  .support-button__container {
    height: 48px;
  }

  .support-button__telegram-on-icon {
    width : 32px;
    height: 32px;
  }

}

/* Settings for mobile devices only */

@media screen and (max-width: 480px) {

  #support-button__subtitle-id {
    display: none; /* Hide subtitle */
  }

}
